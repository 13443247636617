import React from 'react';

/**
 * VideoPlayer Component
 * 
 * A reusable video player component that renders an HTML5 video element
 * with consistent styling and aspect ratio.
 * 
 * @param {Object} props - Component props
 * @param {string} props.videoUrl - URL of the video to be played
 * @returns {JSX.Element} A styled video player element
 * 
 * @example
 * <VideoPlayer videoUrl="https://example.com/video.mp4" />
 */
const VideoPlayer = ({ videoUrl }) => {
  return (
    <div className="aspect-w-16 aspect-h-9">
      <video
        controls
        className="rounded-lg shadow-xl w-full"
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
