import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HeroSection from "./components/home/HeroSection";
import Copnote from "./components/copnote";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HeroSection />} />
        <Route path="/copnote" element={<Copnote />} />
      </Routes>
    </Router>
  );
}

export default App;
