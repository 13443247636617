import React from 'react';
import VideoPlayer from '../player/VideoPlayer';

import oldPolice from '../../assets/old-police.png';
import newPolice from "../../assets/new-police.png";
const demoVideo = 'https://talkfill-portfolio.s3.us-east-1.amazonaws.com/assests/DIR_Short_Demo_1.1.mp4';

/**
 * Copnote Component
 * 
 * A comprehensive page showcasing the evolution of police documentation methods,
 * from traditional paperwork to modern voice-to-text solutions. The component
 * is divided into four main sections:
 * 1. Headline - Displays the CopNote title
 * 2. Old Police Method - Shows traditional documentation approach
 * 3. New Police Method - Demonstrates modern technology solution
 * 4. Demo Video - Presents the product in action
 * 
 * @returns {JSX.Element} The rendered Copnote page
 */
const Copnote = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="py-16 text-center">
        <h1 className="text-5xl font-bold text-gray-900">CopNote</h1>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <div className="lg:w-1/2">
            <div className="aspect-w-4 aspect-h-3">
              <img
                src={oldPolice}
                alt="Old Police Method"
                className="rounded-lg shadow-xl w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="lg:w-1/2 space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">Current Situation</h2>
            <p className="text-xl text-gray-600">
              Police officers dedicate as much as 30% of their time to manually completing incident reports.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col-reverse lg:flex-row items-center gap-12">
            <div className="lg:w-1/2 space-y-6">
              <h2 className="text-3xl font-bold text-gray-900">How CopNote Can Help</h2>
              <p className="text-xl text-gray-600">
              With CopNote, officers can simply dictate the details of an incident into the app. 
              Our AI technology automatically fills the relevant incident report form, resulting in substantial time savings.
              </p>
            </div>
            <div className="lg:w-1/2">
              <div className="aspect-w-4 aspect-h-3">
                <img
                  src={newPolice}
                  alt="New Police Method"
                  className="rounded-lg shadow-xl w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">
          See CopNote in Action
        </h2>
        <VideoPlayer videoUrl={demoVideo} />
      </div>
    </div>
  );
};

export default Copnote;
